<template>
  <div :class="[`${i18n.locale == 'ar' ? 'rtl_parent' : ''}`, 'app_main']">
    <v-app>
      <SideDrawer />
      <AppNav
        v-if="
          route.name != 'signup' &&
          route.name != 'signin' &&
          route.name != 'forgetPassword' &&
          currentWidth >= 960
        "
        dir="rtl"
      />
      <ResponsiveNav
        v-if="
          route.name != 'signup' &&
          route.name != 'signin' &&
          route.name != 'forgetPassword' &&
          currentWidth < 960
        "
      />
      <v-main style="background: rgb(245 245 245 / 70%)">
        <div>
          <slot></slot>
        </div>
      </v-main>
      <AppFooter
        v-if="
          route.name != 'signup' &&
          route.name != 'signin' &&
          route.name != 'forgetPassword'
        "
      />
    </v-app>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import AppFooter from "./AppFooter.vue";
import AppNav from "./AppNav.vue";
import ResponsiveNav from "./ResponsiveNav.vue";
import SideDrawer from "./SideDrawer.vue";
import { getCurrentInstance } from "vue";

const i18n = getCurrentInstance().appContext.config.globalProperties.$i18n;
//Init Router
const route = useRoute();
const currentWidth = ref(0);

onMounted(() => {
  currentWidth.value = window.innerWidth;
  window.addEventListener("resize", () => {
    currentWidth.value = window.innerWidth;
  });
});
</script>
