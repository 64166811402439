import { createRouter, createWebHistory } from "vue-router";
import { authStore } from "@/stores/auth.js";
import { storeToRefs } from "pinia";

const routes = [
  {
    path: "/sign-up",
    name: "signup",
    component: () => import("@/views/SignUp"),
  },
  {
    path: "/sign-in",
    name: "signin",
    component: () => import("@/views/SignIn"),
  },
  {
    path: "/forget-password",
    name: "forgetPassword",
    component: () => import("@/views/ForgetPassword"),
  },
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomePage"),
  },
  {
    path: "/flight-list",
    name: "flightList",
    component: () => import("@/views/FlightResults"),
  },
  {
    path: "/about-us",
    name: "aboutUs",
    component: () => import("@/views/AboutUs"),
  },
  {
    path: "/flight-list/complete-reservation",
    name: "completeReserve",
    component: () => import("@/views/CompleteReserve"),
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: () => import("@/views/PrivacyPolicy"),
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("@/views/TermsPage"),
  },
  {
    path: "/contact-us",
    name: "contactUs",
    component: () => import("@/views/ContactUs"),
  },
  {
    path: "/profile",
    name: "profilePage",
    component: () => import("@/views/profile/ProfilePage"),
    children: [
      {
        path: "",
        name: "profileInfo",
        component: () => import("@/views/profile/children/ProfileInfo"),
      },
      {
        path: "my-bookings",
        name: "profileOrders",
        component: () => import("@/views/profile/children/ProfileOrders"),
      },
      {
        path: "delete-profile",
        name: "profileDelete",
        component: () => import("@/views/profile/children/DeleteProfile"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const authModule = authStore();
  const { loggerState } = storeToRefs(authModule);
  await authModule.checkAuth();
  if (loggerState.value != 0) {
    if (to.name == "signin" || to.name == "signup")
      return next(from.path ? from.path : "/");
  } else {
    if (to.path.includes("profile")) return next("/");
  }
  next();
});

export default router;
