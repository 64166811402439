<template>
  <div class="res_nav">
    <v-app-bar
      density="compact"
      height="100"
      style="border-bottom: 1px solid #e9ecef"
      elevation="0"
    >
      <v-container class="py-0 px-6">
        <v-row class="align-center">
          <v-col cols="2">
            <div class="nav_logo_container d-flex">
              <v-app-bar-nav-icon
                style="font-size: 25px"
                color="grey"
                @click="drawer = !drawer"
              ></v-app-bar-nav-icon>
              <img src="@/assets/logo.png" alt="" width="130" />
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="4">
            <div class="register_btns d-flex justify-end" style="gap: 10px">
              <div class="langs">
                <v-btn
                  variant="elevated"
                  elevation="0"
                  size="45"
                  @click="changeLang"
                  >{{ t("navbar.buttons.lang") }}</v-btn
                >
              </div>
              <div class="profile_btn" v-if="loggerState != 0">
                <v-btn
                  variant="elevated"
                  elevation="0"
                  size="45"
                  id="profile_menu_activator_responsive"
                >
                  <v-avatar
                    ><v-icon color="orange-darken-2"
                      >mdi-account</v-icon
                    ></v-avatar
                  >
                </v-btn>
                <v-menu
                  activator="#profile_menu_activator_responsive"
                  transition="slide-y-transition"
                  location="bottom right"
                  class="profile_menu"
                  offset="20"
                  width="200"
                >
                  <v-list
                    base-color="red"
                    style="box-shadow: 0 0 20px 0 rgba(62, 28, 131, 0.1)"
                  >
                    <v-list-item class="top_bar">
                      <v-list-item-title>
                        {{ t("navbar.profile.menu.hi") }},
                        {{ loggerData.user.name.split(" ")[0] }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'profileInfo' }">
                      <v-list-item-title>
                        <v-icon class="me-2"
                          >mdi-card-account-details-outline</v-icon
                        >
                        {{ t("navbar.profile.menu.profile") }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      :to="{ name: 'profileOrders' }"
                      v-if="!route.path.includes('profile')"
                    >
                      <v-list-item-title>
                        <v-icon class="me-2">mdi-checkbook</v-icon>
                        {{ t("navbar.profile.menu.booking") }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="signOut">
                      <v-list-item-title>
                        <v-icon class="me-2">mdi-logout</v-icon>
                        {{ t("navbar.profile.menu.logout") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div class="profile_btn" v-if="loggerState == 0">
                <v-btn
                  variant="elevated"
                  elevation="0"
                  size="45"
                  @click="router.push({ name: 'signin' })"
                >
                  <v-avatar>
                    <v-icon class="me-1" size="20" color="orange-darken-2"
                      >mdi-login</v-icon
                    >
                  </v-avatar>
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row></v-container
      >
    </v-app-bar>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import { authStore } from "@/stores/auth.js";
import { storeToRefs } from "pinia";
import { getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";

const i18n = getCurrentInstance().appContext.config.globalProperties.$i18n;

const { t } = useI18n();

// Init Router
const authModule = authStore();

// Init Store
const router = useRouter();
const route = useRoute();

// Data
const { loggerState, drawer, loggerData } = storeToRefs(authModule);

// Methods
const signOut = () => {
  authModule.logOut();
};

const changeLang = () => {
  // i18n.locale == "ar" ? (i18n.locale = "en") : (i18n.locale = "ar");
  localStorage.setItem("lang", i18n.locale == "ar" ? "en" : "ar");
  location.reload();
};
</script>

<style lang="scss">
.res_nav {
  .register_btns {
    .langs {
      button {
        background-color: #ebeff2;
        transition: color 0.3s, background 0.3s;
        font-weight: 600;
        letter-spacing: 0.2px;
        text-transform: capitalize;
        font-size: 13px;
        color: #022f5d;
        text-decoration: none;
        text-transform: capitalize;
        &:hover {
          color: rgb(255, 115, 0);
        }
      }
    }
    button {
      background-color: rgb(255, 115, 0);
      color: white;
      text-transform: capitalize;
      transition: color 0.3s, background 0.3s;
      font-weight: 600;
      letter-spacing: 0.2px;
      text-transform: capitalize;
      font-size: 13px;
      text-decoration: none;
      text-transform: capitalize;
    }
  }
  .profile_btn {
    button {
      background-color: rgba(255, 115, 0, 0.188);
      transition: color 0.3s, background 0.3s;
      font-weight: 600;
      letter-spacing: 0.2px;
      text-transform: capitalize;
      font-size: 13px;
      color: #022f5d;
      text-decoration: none;
      text-transform: capitalize;
      &:hover {
        color: rgb(255, 115, 0);
      }
    }
  }
}
</style>
