<template>
  <div class="side_drawer">
    <v-navigation-drawer
      v-model="drawer"
      location="left"
      :width="breakPoints.display.xs ? '270' : 350"
    >
      <v-card elevation="0">
        <v-list>
          <v-list-item class="text-end py-4">
            <v-icon size="30" color="grey-darken-1" @click="drawer = false"
              >mdi-close</v-icon
            >
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            class="py-4 ps-7"
            :to="{ name: 'home' }"
            base-color="orange"
          >
            <router-link class="nav_link" :to="{ name: 'home' }">{{
              t("navbar.links.home")
            }}</router-link>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            class="py-4 ps-7"
            :to="{ name: 'aboutUs' }"
            base-color="orange"
          >
            <router-link class="nav_link" :to="{ name: 'aboutUs' }">{{
              t("navbar.links.about")
            }}</router-link>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            class="py-4 ps-7"
            :to="{ name: 'contactUs' }"
            base-color="orange"
          >
            <router-link class="nav_link" :to="{ name: 'contactUs' }">{{
              t("navbar.links.contact")
            }}</router-link>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            class="py-4 ps-7"
            :to="{ name: 'privacyPolicy' }"
            base-color="orange"
          >
            <router-link class="nav_link" :to="{ name: 'privacyPolicy' }">{{
              t("navbar.links.policy")
            }}</router-link>
          </v-list-item>
          <v-list-item
            class="py-4 ps-7"
            :to="{ name: 'Terms' }"
            base-color="orange"
          >
            <router-link class="nav_link" :to="{ name: 'Terms' }">{{
              t("navbar.links.terms")
            }}</router-link>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script setup>
import { authStore } from "@/stores/auth.js";
import { storeToRefs } from "pinia";
import { createVuetify } from "vuetify";
import { ref } from "vue";

import { useI18n } from "vue-i18n";

const { t } = useI18n();

// Init Store
const authModule = authStore();
const vuetify = createVuetify();
const breakPoints = ref(vuetify);

// Data
const { drawer } = storeToRefs(authModule);
</script>

<style lang="scss">
.side_drawer {
  a.nav_link {
    display: inline-block;
    transition: color 0.3s, background 0.3s;
    font-weight: 600;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    font-size: 15px;
    color: #022f5d;
    text-decoration: none;
    &:hover {
      color: rgb(255, 115, 0);
    }

    &.router-link-exact-active {
      color: rgb(255, 115, 0);
    }
  }
}
</style>
