<template>
  <div class="app_footer" style="margin-top: 90px">
    <v-footer
      color="grey-lighten-3"
      class="pt-14 px-0 pb-0"
      :app="true"
      absolute
    >
      <v-container class="px-10">
        <v-row>
          <v-col cols="12" sm="6" md="7" class="logo">
            <div>
              <img src="@/assets/logo.png" alt="" width="180" />
            </div>
            <p class="mt-2">
              {{ t("navbar.footer.slogan_first") }} <br />
              {{ t("navbar.footer.slogan_second") }}
            </p>
            <div class="social_media">
              <v-icon>mdi-facebook</v-icon>
              <v-icon>mdi-linkedin</v-icon>
              <v-icon>mdi-twitter</v-icon>
              <v-icon>mdi-youtube</v-icon>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="footer_links mt-13 mt-sm-0">
            <h3>{{ t("navbar.footer.company") }}</h3>
            <p>
              <router-link :to="{ name: 'home' }">{{
                t("navbar.links.home")
              }}</router-link>
            </p>
            <p>
              <router-link :to="{ name: 'aboutUs' }">{{
                t("navbar.links.about")
              }}</router-link>
            </p>
            <p>
              <router-link :to="{ name: 'contactUs' }">{{
                t("navbar.links.contact")
              }}</router-link>
            </p>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="footer_payment mt-13 mt-md-0">
            <h3>{{ t("navbar.footer.methods") }}</h3>
            <div class="imgs d-flex align-center" style="gap: 7px">
              <img width="70" src="@/assets/SVGS/visa.svg" alt="" />
              <img width="70" src="@/assets/SVGS/mastercard.svg" alt="" />
              <img width="70" src="@/assets/SVGS/pay.svg" alt="" />
              <img width="70" src="@/assets/SVGS/discover.svg" alt="" />
              <img width="70" src="@/assets/SVGS/paypal.svg" alt="" />
            </div>
          </v-col>
          <v-col cols="12" class="mt-16">
            <div
              class="copyright d-flex flex-column flex-sm-row justify-space-between align-start align-sm-center"
            >
              <p>
                {{ t("navbar.footer.copyright") }} @{{
                  new Date().getFullYear()
                }}
              </p>
              <div
                class="links mt-2 mt-sm-0 d-flex align-center"
                style="gap: 10px"
              >
                <router-link :to="{ name: 'Terms' }">{{
                  t("navbar.links.terms")
                }}</router-link>
                <v-divider vertical></v-divider>
                <router-link :to="{ name: 'privacyPolicy' }">{{
                  t("navbar.links.policy")
                }}</router-link>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<style lang="scss">
.app_footer {
  .logo {
    p {
      font-size: 14px;
      color: rgb(96, 96, 96);
    }
    .social_media {
      margin-top: 30px;
      i {
        margin-right: 10px;
        background-color: #dfe8edb3;
        width: 40px;
        height: 40px;
        font-size: 18px;
        border-radius: 5px;
        color: rgba(72, 86, 110, 0.755);
      }
    }
  }
  h3 {
    margin-bottom: 10px;
    font-size: 17px;
    color: #003a75;
  }
  a {
    display: inline-block;
    transition: color 0.3s, background 0.3s;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    font-size: 13px;
    color: #6e8497;
    text-decoration: none;
    margin-bottom: 15px;
    &:hover {
      color: rgb(255, 115, 0);
    }

    &.router-link-exact-active {
      color: rgb(255, 115, 0);
    }
  }
  .footer_payment {
    h3 {
      margin-bottom: 10px;
      font-size: 17px;
      color: #003a75;
    }
  }
  .copyright {
    border-top: 2px solid rgb(220, 220, 220);
    padding: 15px 0;
    p {
      font-size: 14px;
      color: #5d6f7d;
      margin-bottom: 0;
    }
  }
}
</style>
