import { defineStore } from "pinia";
import { mainStore } from "@/stores/main.js";
import axios from "axios";

export const authStore = defineStore("authStore", {
  state: () => ({
    loggerData: {},
    loginErr: "",
    signupErrs: {},
    loggerState: 0,
    drawer: false,
  }),
  actions: {
    createCookie(date, token) {
      let expires = "";
      date.setTime(date.getTime() + 60 * 60 * 1000 * 12);
      expires = "; expires=" + date.toUTCString() + `;path=/`;
      document.cookie = "flightyLg" + "=" + token + expires;
    },
    async createNewAccount(data) {
      this.signupErrs = {};
      let result;
      const formData = new FormData();
      for (const val of Object.entries(data)) {
        formData.append(val[0], val[1]);
      }
      await axios
        .post(`${mainStore().apiURL}/register?verify_place=web`, formData, {
          headers: {
            "Content-Type": "form-data",
          },
        })
        .then(() => {
          result = true;
        })
        .catch((err) => {
          this.signupErrs = err.response.data.data;
          result = false;
          if (!err.response) {
            mainStore().handleErr(
              "error",
              `Connection Error`,
              "Something went wrong, please check your internet connection and try again later"
            );
          }
        });
      return result;
    },
    async activateEmail(data) {
      let result;
      await axios
        .post(`${mainStore().apiURL}/activateAccountEmailed`, data)
        .then((res) => {
          this.loggerData = res.data.data;
          // Set Cookie
          this.createCookie(new Date(), res.data.data.token);
          result = true;
        })
        .catch((err) => {
          result = false;
          mainStore().handleErr(
            "error",
            `${
              err.response && err.response.data
                ? "Incomplete Request"
                : "Connection Error"
            }`,
            err.response && err.response.data
              ? err.response.data.message
              : "Something went wrong, please check your internet connection and try again later"
          );
        });
      return result;
    },
    async doLogin(data) {
      this.loginErr = "";
      this.loggerData = {};
      let result;
      const formData = new FormData();
      formData.append("username", data.email);
      formData.append("password", data.password);
      await axios
        .post(`${mainStore().apiURL}/login`, formData)
        .then((res) => {
          this.loggerData = res.data.data;
          // Set Cookie
          this.createCookie(new Date(), res.data.data.token);
          result = true;
        })
        .catch((err) => {
          mainStore().handleErr(
            "error",
            `${
              err.response && err.response.data
                ? "Incomplete Request"
                : "Connection Error"
            }`,
            err.response && err.response.data
              ? err.response.data.message
              : "Something went wrong, please check your internet connection and try again later"
          );
          result = false;
        });
      return result;
    },
    async checkAuth() {
      const cookie = this.getCookie();
      if (cookie.flightyLg) {
        if (this.loggerData.token) {
          this.loggerState = 1;
        } else {
          this.loggerState = 2;
          await this.getUserData();
        }
      } else {
        this.loggerState = 0;
      }
    },
    async getUserData() {
      let result;
      const cookie = this.getCookie();
      await axios
        .get(`${mainStore().apiURL}/profile`, {
          headers: {
            Authorization: `Bearer ${cookie.flightyLg}`,
          },
        })
        .then((res) => {
          this.loggerData.user = res.data;
          this.loggerData.token = cookie.flightyLg;
          result = true;
        })
        .catch((err) => {
          mainStore().handleErr(
            "error",
            `${
              err.response && err.response.data
                ? "Incomplete Request"
                : "Connection Error"
            }`,
            err.response && err.response.data
              ? err.response.data.message
              : "Something went wrong, please check your internet connection and try again later"
          );
          result = false;
        });
      return result;
    },
    async updateProfileInfo(data) {
      let result;
      await axios
        .put(`${mainStore().apiURL}/update`, data, {
          headers: {
            Authorization: `Bearer ${this.loggerData.token}`,
          },
        })
        .then(() => {
          mainStore().handleErr(
            "success",
            `Updated Successfully`,
            "Your profile information has been updated successfully"
          );
          this.getUserData();
        })
        .catch((err) => {
          mainStore().handleErr(
            "error",
            `${
              err.response && err.response.data
                ? "Incomplete Request"
                : "Connection Error"
            }`,
            err.response && err.response.data
              ? err.response.data.message
              : "Something went wrong, please check your internet connection and try again later"
          );
          result = false;
        });
      return result;
    },
    async updateProfilePassword(data) {
      let result;
      await axios
        .post(`${mainStore().apiURL}/change-password`, data, {
          headers: {
            Authorization: `Bearer ${this.loggerData.token}`,
          },
        })
        .then(() => {
          mainStore().handleErr(
            "success",
            `Updated Successfully`,
            "Your password has been updated successfully"
          );
          result = true;
        })
        .catch((err) => {
          mainStore().handleErr(
            "error",
            `${
              err.response && err.response.data
                ? "Incomplete Request"
                : "Connection Error"
            }`,
            err.response && err.response.data
              ? err.response.data.message
              : "Something went wrong, please check your internet connection and try again later"
          );
          result = false;
        });
      return result;
    },
    getCookie() {
      const cookies = {};
      const cookieString = document.cookie;
      const cookieArray = cookieString.split("; ");
      cookieArray.forEach((cookie) => {
        const [name, ...valueParts] = cookie.split("=");
        const value = valueParts.join("=");
        const decodedName = decodeURIComponent(name);
        const decodedValue = decodeURIComponent(value);
        cookies[decodedName] = decodedValue;
      });
      return cookies;
    },
    logOut() {
      // Set Cookie
      let expires = "";
      let date = new Date();
      date.setTime(date.getTime());
      expires = "; expires=" + date.toUTCString() + `;path=/`;
      document.cookie = "flightyLg" + "=" + "test" + expires;
      setTimeout(() => {
        location.reload();
      }, 200);
    },
    async deleteAccount() {
      let result;
      const token = decodeURIComponent(document.cookie).split("=")[1];
      await axios
        .get(`${mainStore().apiURL}/delete-account`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.logOut();
          result = true;
        })
        .catch((err) => {
          mainStore().handleErr(
            "error",
            `${
              err.response && err.response.data
                ? "Incomplete Request"
                : "Connection Error"
            }`,
            err.response && err.response.data
              ? err.response.data.message
              : "Something went wrong, please check your internet connection and try again later"
          );
          result = false;
        });
      return result;
    },
  },
});
