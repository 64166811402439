import navbar from "./navbar.json";
import homepage from "./homepage.json";
import about from "./about.json";
import terms from "./terms.json";
import privacy from "./privacy.json";
import contact from "./contact.json";
import auth from "./auth.json";
import flights from "./flights.json";
import errors from "./errors.json";

export default {
  ...navbar,
  ...homepage,
  ...about,
  ...terms,
  ...privacy,
  ...contact,
  ...auth,
  ...flights,
  ...errors,
};
